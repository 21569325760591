<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="AP Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Vendor Code</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedVendors"
                  :options="optionVendors"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  track-by="id"
                  :custom-label="customLabelVendors"
                  @search-change="findVendors"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Doc. Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="docPostingDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearPostingDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Invoice Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="invoiceDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearInvoiceDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Due Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="dueDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearDueDate()">X</vs-button>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :territoryIDs="this.territoryIDs"
                  :territoryNames="this.territoryNames"
                  :startPostingDate="this.docPostingDate.startDate"
                  :endPostingDate="this.docPostingDate.endDate"
                  :startInvoiceDate="this.invoiceDate.startDate"
                  :endInvoiceDate="this.invoiceDate.endDate"
                  :startDueDate="this.dueDate.startDate"
                  :endDueDate="this.dueDate.endDate"
                  :draw="this.draw"
                  :vendorIDs="this.vendorIDs"
                  :vendorNames="this.vendorNames"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { debounce } from 'lodash';

export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/report/ap-export',
      selectedTerritories: [],
      optionTerritories: [],
      selectedVendors: [],
      optionVendors: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      docPostingDate: {
        startDate: null,
        endDate: null,
      },
      invoiceDate: {
        startDate: null,
        endDate: null,
      },
      dueDate: {
        startDate: null,
        endDate: null,
      },
      isLoading: false,
      vendorQuery: '',
      vendorIDs: [],
      vendorNames: [],
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customLabelVendors(val) {
      if (val) {
        return val.Code === '-' ? `${val.Name}` : `(${val.Code}) ${val.Name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },
    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getVendorCodes() {
      this.isLoading = true;
      const queryString = '?search=' + this.vendorQuery;
      this.$http
        .get(
          '/api/report/v1/master/vendor' +
            (this.vendorQuery && this.vendorQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionVendors = resp.data.records;
          this.isLoading = false;
        });
    },
    findVendors: debounce(function (query) {
      this.vendorQuery = query;
    }, 500),
    handleDrawTable() {
      this.draw++;
    },
    clearPostingDate() {
      this.docPostingDate.startDate = null;
      this.docPostingDate.endDate = null;
    },
    clearInvoiceDate() {
      this.invoiceDate.startDate = null;
      this.invoiceDate.endDate = null;
    },
    clearDueDate() {
      this.dueDate.startDate = null;
      this.dueDate.endDate = null;
    },
  },
  mounted() {
    this.getTerritories();
    this.getVendorCodes();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedVendors(val) {
      let vendorIDs = [];
      let vendorCodes = [];
      let vendorNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          vendorIDs.push(e.id);
          vendorCodes.push(e.Code);
          vendorNames.push(e.Name);
        }
      });
      this.vendorIDs = vendorIDs;
      this.vendorNames = vendorNames;
    },
    vendorQuery(val) {
      this.getVendorCodes();
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
